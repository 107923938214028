import { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { IPlayer } from './session.component';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';


const AddGame = () => {
  const navigate = useNavigate();

  const location = useLocation()
  const { session } = location.state

  const [gameWinner, setGameWinner] = useState<IPlayer>()
  const [gameFiregun, setGameFiregun] = useState<IPlayer>()
  const [gameScore, setGameScore] = useState<number>(3)
  const [gameDraw, setGameDraw] = useState<boolean>(false);

  return (
    <>
      <Container>

        {gameDraw !== true && (
          <Row>
            <Col>Winner</Col>
            <Col>
              <DropdownButton id="dropdown-basic-button" title={gameWinner !== undefined ? gameWinner.Name : 'Select Winner'}>
                {session.activePlayers.length !== 0 && session.activePlayers.map((player: IPlayer, i: number) => (
                  <Dropdown.Item onClick={() => { setGameWinner(player) }}>{player.Name}</Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
        )}

        {gameDraw !== true && gameWinner !== null && (
          <Row>
            <Col>Firegun</Col>
            <Col>
              <DropdownButton id="dropdown-basic-button" title={gameFiregun !== undefined ? gameFiregun.Name : 'Select Firegun'}>
                {session.activePlayers.length !== 0 && session.activePlayers.filter((player: IPlayer) => gameWinner !== player).map((player: IPlayer, i: number) => (
                  <Dropdown.Item onClick={() => { setGameFiregun(player) }}>{player.Name}</Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
        )}

        {gameDraw !== true && (
          <Row>
            <Col>Points</Col>
            <Col>
              <DropdownButton id="dropdown-basic-button" title={gameScore}>
                {[...Array(14).keys()].map((score: number) => (
                  <Dropdown.Item onClick={() => { setGameScore(score) }}>{score}</Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
        )}

        <Row>
          <Col>Draw?</Col>
          <Col>
            <Form.Check
              type="switch"
              defaultChecked={gameDraw}
              onChange={(e) => {
                setGameDraw(e.target.checked)
                setGameWinner(undefined)
                setGameFiregun(undefined)
                setGameScore(0)
              }}
            ></Form.Check>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Link to={''} onClick={() => addGame(session.id)}>Add Game</Link>
        </Row>
        <Row>
          <Link to={''} onClick={() => navigate(-1)}>Cancel</Link>
        </Row>
      </Container>
    </>
  )

  function addGame(sessionId: string) {
    console.log(`adding game for session id ${sessionId}: {
      winner: ${gameWinner ? gameWinner.Name : 'nah'}, 
      firegun: ${gameFiregun ? gameFiregun.Name : 'nah'}, 
      score: ${gameScore}, 
      draw: ${gameDraw}
    }`)
    navigate(-1)
  }
}

export default AddGame