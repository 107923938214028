import { useMemo, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Session, { IGame, IPlayer } from './session.component';
import { Container, Row, Col } from 'react-bootstrap';
import Game from './game.component';
import ScoreAmount from '../helpers/score-amount.helper';


const SessionDetail = () => {
  const navigate = useNavigate();

  const location = useLocation()
  const { session } = location.state

  const [games, setGames] = useState<IGame[]>([])

  const [totalScoresByPlayer, setTotalScoresByPlayer] = useState<{ [playerId: string]: number }>({})
  useMemo(() => {
    setGames(session.games)

    const scoreMapByPlayer: { [playerId: string]: number } = {}
    const playerMap: { [playerId: string]: IPlayer } = {}
    session.allPlayers.forEach((player: IPlayer) => {
      if (!playerMap[player.Id]) playerMap[player.Id] = player
      if (!scoreMapByPlayer[player.Id]) scoreMapByPlayer[player.Id] = 0 
    })
    session.games.forEach((game: IGame) => {
      game.players.forEach((player: IPlayer) => {
        scoreMapByPlayer[player.Id] += ScoreAmount(game, playerMap[player.Id]);
      })
    })
    setTotalScoresByPlayer(scoreMapByPlayer)
  }, [session.games, session.allPlayers])

  return (
    <div>
      <Container>
        <Row>
        <Link to={''} onClick={() => navigate(-1)}>Back</Link>
        </Row>
      </Container>
      <Session session={session} className='session-list-item'/>
      <Container>
        <Row>
          <Col>Current Table</Col>
          <Col>Prevailing Wind: {session.prevailingWind}</Col>
        </Row>
        <Row>
          <Col>Seat</Col>
          <Col>Score</Col>
          <Col>Seat Wind</Col>
        </Row>
        {session.activePlayers.length !== 0 && session.activePlayers.map((player: IPlayer, i: number) => (
          <Row key={player.Id}>
            <Col>{i+1}: {player.Name}</Col>
            <Col>{totalScoresByPlayer[player.Id]}</Col>
            <Col>{session.seatWinds[i]}</Col>
          </Row>
        ))}
        <Row>
          <Link to={'add-game/'} state={{ session }}>Add Game</Link>
        </Row>
      </Container>
      <Container>
        {games != null && games.map((game: IGame, i) => (
          <Game key={game.id} game={game} />
        ))}
      </Container>
    </div>
  )
}



export default SessionDetail