import { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { IGame, IPlayer } from './session.component';
import ScoreAmount from '../helpers/score-amount.helper';

interface GameProps {
  game: IGame
}

type PlayersMap = { [key: string]: IPlayer; }

const Game = (gameProps: GameProps) => {
  const { game } = gameProps;

  const [playersMap, setPlayersMap] = useState<PlayersMap>({})
  useEffect(() => {
    const plMap: PlayersMap = {}
    game.players.forEach(player => {
      plMap[player.Id] = player;
    })
    setPlayersMap(plMap)

  }, [game.players])

  return (
    <Card className='game-item'>
      <Row>
        <Col>{playersMap[game.winnerId] ? playersMap[game.winnerId].Name : '-'}</Col>
        <Col style={{ display:'flex', justifyContent:'right' }}>
          {ScoreAmount(game, playersMap[game.winnerId] ? playersMap[game.winnerId] : undefined)}
        </Col>
      </Row>
    </Card>
  )
}

export default Game