import { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import Session, { ISession } from './session.component';

const SessionList = () => {
  const [sessions, setSessions] = useState<ISession[]>([])

  useEffect(() => {
    getAllSessions();
  }, [])

  return (
    <div>
      {/* list all current sessions */}
      <Container>
        <Row>
          <Col>Session Date</Col>
          <Col>Players</Col>
          <Col>Games Played</Col>
        </Row>
        {sessions !== null && sessions.map((session: ISession, i) => (
          <Link to={`/session/${session.id}`} state={{ session }}>
            <Session key={session.id} session={session} />
          </Link>
        ))}
      </Container>
    </div>
  )

  function getAllSessions() {
    const url = `${process.env.REACT_APP_BASE_URL}/sessions`
    axios.get(url, {
      responseType: 'json',
    }).then(response => {
      if (response.status === 200) {
        setSessions(response.data.Sessions)
      }
    })
  }
}

export default SessionList