import { useState, useEffect, ReactElement, ComponentPropsWithoutRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Card, Row, Col } from 'react-bootstrap';

interface SessionProps extends ComponentPropsWithoutRef<any> {
  session: ISession
}

export interface IPlayer {
  Id: string
  Name: string
  PlayerColour: string
}

export interface IGame {
  id: string
  sessionId: string
  players: IPlayer[]
  points: number
  winnerId: string
  firegunId: string
  selfPick: boolean
  winnerSeatWind: string
  prevailingWind: string
  createdAt: string
}

export interface ISession {
  id: string
  activePlayers: IPlayer[]
  allPlayers: IPlayer[]
  games: IGame[]
  seatWinds: string[]
  prevailingWind: string
  createdAt: string
}

const Session = (sessionData: SessionProps) => {
  const { session } = sessionData;

  const [createdAt, setCreatedAt] = useState<String>()
  useEffect(() => {
    const createdAtDate = new Date(session.createdAt).toDateString()
    setCreatedAt(createdAtDate)
  }, [session.createdAt])

  const [playerIcons, setPlayerIcons] = useState<ReactElement[]>([])
  useEffect(() => {
    const playerIcons: ReactElement[] = session.allPlayers.map((player: IPlayer) => {
      const playerInitial = player.Name[0];
      const playerColour = player.PlayerColour;
      return (<div data-initials={playerInitial} className='player-icon' style={{ background: `#${playerColour}` }} />)
    });

    setPlayerIcons(playerIcons)
  }, [session.allPlayers])

  return (
    <Card className='session-list-item'>
      <Row>
        <Col>{createdAt !== undefined && createdAt}</Col>
        <Col>{session.allPlayers !== undefined && playerIcons}</Col>
        <Col>Games: {session.games !== undefined && session.games.length}</Col>
      </Row>
    </Card>
  )
}

export default Session