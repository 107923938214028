import { IGame, IPlayer } from '../components/session.component';

const ScoreAmount = (game: IGame, player?: IPlayer) => {
  if (game.points === 0) { return 0 }
  if (player === undefined) { return 0 }

  const scoreMap: { [key: number]: number } = {
    0: 0,
    1: 2,
    2: 4,
    3: 8,
    4: 16,
    5: 32,
    6: 64,
    7: 128,
    8: 256,
    9: 512,
    10: 1024,
    11: 2048,
    12: 4096,
    13: 8192,
  }

  const isPlayerWinner = game.winnerId === player.Id
  const isFiregunPresent = game.firegunId !== ""
  const isSelfPick = game.selfPick
  const isPlayerFiregun = isFiregunPresent && game.firegunId === player.Id

  if (isPlayerWinner) {
    if (isFiregunPresent) {
      if (isSelfPick) { return scoreMap[game.points] * 3 / 2 }
      else { return scoreMap[game.points] }
    } else {
      return (scoreMap[game.points] / 2) * 3
    }
  } else {
    if (isFiregunPresent) {
      if (isPlayerFiregun) {
        if (isSelfPick) { return -(scoreMap[game.points] * 3) / 2 }
        else { return -scoreMap[game.points] }
      } else {
        return 0
      }
    } else {
      return -scoreMap[game.points] / 2
    }
  }
}

export default ScoreAmount