import './App.css';

import SessionList from './components/session-list.component';

function App() {
  return (
    <div className="App">
      <SessionList />
    </div>
  );
}

export default App;
